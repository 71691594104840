import React from 'react';

import './Fooldal.scss';
import { getAge } from '../../../functions';
import main from './assets';

class Fooldal extends React.Component {
  render() {
    return (
      <div className="fooldal">
        <h1>Üdvözöljük szervizünk honlapján!</h1>
        <img src={main['main_' + getAge()]} alt="Bánfi szerviz" />
      </div>
    );
  }
}

export default Fooldal;

import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import './App.scss';
import Header from '../../fragments/Header/Header';
import Main from '../Main/Main';

const history = createBrowserHistory();

class App extends React.Component {
  contentRef = React.createRef();

  render() {
    return (
      <Router history={history}>
        <div className="banfiszerviz">
          <Header contentRef={this.contentRef} />
          <Main contentRef={this.contentRef} />
        </div>
      </Router>
    );
  }
}

export default App;

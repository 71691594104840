import React from 'react';

class FutomuInfok extends React.Component {
  render() {
    return (
      <div>
        <h1>Futómű információk</h1>
        <h2>Futómű</h2>
        <p>A futómű a gépjárművek biztonsága szempontjából igen lényeges szerkezeti részekből áll, ezért kialakításuknál a közlekedésbiztonság fontos szerepet tölt be.</p>
        <p>
          A gépjármű rendeltetésszerű használata alatt a futóműnek biztosítania kell a gépjármű és az úttest között a állandó kapcsolatot. A futóművek szerkezeti elemei közé
          soroljuk a gépjárművek haladásával, különböző irányba való mozgatásával kapcsolatos részeit; a rugózását, lengéscsillapítását és stabilitását biztosító szerkezeti
          részeket, valamint a kerékmeghajtások részeit is.
        </p>
        <p>
          A kerékfelfüggesztés összeköti a kerekeket az alvázzal és az önhordó felépítménnyel. Átviszi a jármű súlyát és a tömegerőket a kerekekre, másrészt a kerekek fékezési,
          meghajtó és oldalvezetési erőit a felépítményre. A futómű beállításának optimális értékeit a gyárak minden kocsitípushoz pontosan megadják. Ezen előírások elhanyagolása,
          rossz beállítása rendellenes gumikopáshoz, a futómű idő előtti rongálódásához vezethet. A futómű beállításának minősége nagymértékben befolyásolja a jármű
          menetviszonyait.
        </p>
        <h2>Kerékdőlés</h2>
        <p>
          A kerék síkja a függőlegestől általában eltérő . A gépkocsi hossztengelyéhez képest a kerekek felső széle kifelé (pozitív) vagy befelé (negatív) dől. Nagysága típusonként
          változik. Feladatai közé tartozik a kerékcsapágyak egyirányú támasztása (holtjáték korlátozása, csapágy terhelésének csökkentése) és csökkenteni a kormányzási nyomatékot.
          (Nem minden típuson állítható).
        </p>
        <h2>Kerékösszetartás</h2>
        <p>
          Felülnézetben a kerekek egymástól mért távolsága a mellső tengely elött és mögött nem egyezik meg. Ha a tengely előtt kisebb ez a távolság, akkor kerékösszetartásról, ha
          mögötte, akkor széttartásról van szó. A kerékdőlés hatására a kerekek a dőlés irányába igyekeznek elkanyarodni, és az ebből adódó igénybevételt csökkenti ill.
          ellensúlyozza az összetartás. A kerékösszetartás korlátozza a kormányholtjátékot, csökkenti a kerekek szétállási hajlamát, javítja a nyomtartást. Minden autón állítható,
          egyes típusokon a hátsó futóművön is.
        </p>
        <h2>Csapterpesztés</h2>
        <p>
          A csapterpesztés hatására a kerék talppontja és a (csapszeg képzeletbeli meghosszabbított felső és alsó gömbcsukló vonala) a talajon közelebb kerül egymáshoz, így a
          talajellenállás nyomatékának karja csökken, könnyebb lesz az elkormányzás. A csapterpesztés szögértéke mindig nagyobb, mint a kerékdőlésé, ezért kormányzáskor a jármű
          megemelkedik, így visszakormányzáskor a kocsi saját tömege is segíti a vezetőt a kormány visszaforgatásában Előfordul, hogy a „csapszeg” (felső és alsó gömbfej vonal)
          tengelyvonala a kerék talppontján kívül metszi a talajt.
        </p>
        <h2>Utánfutás</h2>
        <p>
          Elősegíti az egyenes úton való haladást. A csapszeg középvonalának elvi meghosszabbítása - pozitív dőlés esetén - haladáskor megelőzi a kerék talppontját, vagyis a
          gépkocsit mozgató erő mintegy maga után húzza a kereket. Negatív dőlésnél a kerék előzi meg az erőhatást, azaz maga után húzza az autót, ezért fordulékonyabbak az első
          kerék-meghajtású gépkocsik.
        </p>
      </div>
    );
  }
}

export default FutomuInfok;

import main_23 from './main_23.jpg';
import main_24 from './main_24.jpg';
import main_25 from './main_25.jpg';
import main_26 from './main_25.jpg';
import main_27 from './main_27.jpg';
import main_28 from './main_28.jpg';
import main_29 from './main_29.jpg';
import main_30 from './main_30.jpg';
import main_31 from './main_31.jpg';
import main_32 from './main_32.jpg';
import main_33 from './main_33.jpg';

export default { main_23, main_24, main_25, main_26, main_27, main_28, main_29, main_30, main_31, main_32, main_33 };

import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Header.scss';
import logo from './assets/futomu200.png';
import header from './assets/head.jpg';

class Header extends React.Component {
  static propTypes = {
    contentRef: PropTypes.node,
  };

  clickHeader = () => {
    if (window.innerWidth < 1280) {
      window.scrollTo(0, this.props.contentRef.current.offsetTop);
    }
  };

  render() {
    return (
      <div className="header-wrapper">
        <div className="header">
          <div className="header__logo">
            <NavLink to="/" onClick={this.clickHeader}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className="header__image">
            <img src={header} alt="header" />
          </div>
        </div>
      </div>
    );
  }
}

export default Header;

import React from 'react';

class Fotogaleria extends React.Component {
  render() {
    return (
      <div>
        <h1>Fotógaléria</h1>
        <h2>Műhely fotók</h2>
      </div>
    );
  }
}

export default Fotogaleria;

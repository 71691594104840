import React from 'react';

import { getAge } from '../../../functions';

import './FutomuBeallitas.scss';
import v2200 from './assets/v2200.jpg';

class FutomuBeallitas extends React.Component {
  render() {
    return (
      <div className="futomu-beallitas">
        <h1>Futómű beállítás</h1>
        <p>
          Elsőként azzal kezdeném, hogy <span className="red bold">futómű-beállítás terén kollégámmal {getAge()} éves tapasztalattal rendelkezünk!</span> Higgyék el, hogy ez idő
          alatt mi már megismertük a futóművek összes rejtelmét. A precíz és pontos jelzőt bátran merem említeni ha rólunk van szó!
        </p>
        <p>
          Ahogy a céginformációban már olvashatták, a <span className="red bold upcase">futómű mérés ingyenes</span> nálunk. Nagyon sok az érdeklődés ezzel kapcsolatban. Hogy mit
          is jelent ez? Sajnos az emberek nehezen hiszik el, hogy a mai világban valami INGYENES, de ha eljön hozzánk egy számítógépes futómű mérésre, (mert pl: belement egy
          nagyobb kátyúba, ami az egész országban sűrűn előfordul, esetleg a frissen vásárolt autó futóműjének az állapotára kíváncsi) mi elvégezzük a munkát, és nem kérünk érte
          semmi féle ellenszolgáltatást sem!
        </p>
        <p>
          És van még valami! Ha már ott van nálunk, és ez az egész számítógépes futómű kontrol nem került pénzébe, akkor is adunk önnek egy{' '}
          <span className="bold upcase">törzsvásárlói kártyát</span> azért, hogy ha legközelebb is minket választ, és esetleg futómű beállítás is esedékes akkor kedvezményben
          részesüljön!
        </p>
        <p>
          A futómű mérés abból áll, hogy szervizünkben mind a négy kerékre felhelyezünk egy egy elektronikus mérő fejet, amelyek infrasugarakkal kapcsolódnak egymáshoz. Az autó
          adatait betápláljuk a számítógépbe, ami azonnal megjeleníti nekünk a gyári futómű értékeket, és összehasonlítja a jelenleg mért értékekkel. A futóműbeállító berendezésünk
          információ ad mind a négy kerék dőléséről, összetartásáról, utánfutásáról, csapterpesztéséről, és a szimetriatengelytől való szögeltéréséről. Erről nyomtatott
          dokumentumot is adunk önnek! A jármű adatbankunk 30 évre visszamenőleg fel van töltve.
        </p>
        <p>A futómű beállító műhelyünkben is magasan képzett szakemberek dolgoznak azért hogy ön maximálisan elégedett legyen!</p>

        <h2>John Bean V2200 futóműállító</h2>

        <img className="v2200" src={v2200} alt="John Bean V2200 futóműállító" />

        <p className="bold">A sokoldalú és precíz hardver</p>

        <ul>
          <li>Nagy felbontású kamerák szolgáltatják a pontosságot, az élő adatok megjelenítését és a mérési eredményeket.</li>
          <li>Az új fejlesztésű XD mérőpajzsok nem tartalmaznak elektronikus alkatrészt, ezáltal karbantartás – és sérülésmentesek.</li>
          <li>A továbbfejlesztett XD mérőpajzsok és az AC100 műszerállványok könnyűek, de masszívak és ütésállóak.</li>
        </ul>

        <p className="bold">Szabvány méretezés és produktivitást növelő funkciók</p>
        <ul>
          <li>Jármű nyomtáv és kerék adatbázis.</li>
          <li>Egyedi kerékszélesség állítás és kerékeltolódás állítás.</li>
          <li>EZ-TOE* – a kormánykerék rögzítése nélkül mérhető a kerékösszetartás.</li>
          <li>Kormányösszekötő rúd, kormánytoló rúd és a bölcső irányított beállítása.</li>
        </ul>

        <p className="bold">A V2200 futóműállító berendezés az egyik legfejlettebb a piacon kapható műszerek között</p>
        <p>
          Szabadalmazott képalkotó technológiája gondoskodik a pontos és valósidejű mérésekről, melyek javítják a termelékenységet. Felhasználóbarát funkciói többek között az
          EZ-TOE*, VODI* és egy kiterjedt járműspecifikus adatbázis, amit a vevőnek kínál, könnyű használat és pontosság jellemzi.
        </p>

        <p className="bold">AC100 univerzális felfogató rendszer</p>
        <ul>
          <li>Gyors és könnyű használat</li>
          <li>Önbeálló kivitel a jármű kiterjedésének pontos mérésére.</li>
          <li>Kétoldali kiegészítő karmok miatt megbízhatóan rögzíthető, akkor is ha dísztárcsa van a felnin.</li>
          <li>Rendelkezésre állnak kiegészítők nagyobb kerekek befogására.</li>
        </ul>
      </div>
    );
  }
}

export default FutomuBeallitas;

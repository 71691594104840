import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Content.scss';
import Fooldal from '../../pages/Fooldal/Fooldal';
import Ceginformacio from '../../pages/Ceginformacio/Ceginformacio';
import NotFound from '../../pages/NotFound/NotFound';
import FutomuBeallitas from '../../pages/FutomuBeallitas/FutomuBeallitas';
import FutomuInfok from '../../pages/FutomuInfok/FutomuInfok';
import Gumiszerviz from '../../pages/Gumiszerviz/Gumiszerviz';
import GumiInfok from '../../pages/GumiInfok/GumiInfok';
import Fotogaleria from '../../pages/Fotogaleria/Fotogaleria';
import Kapcsolat from '../../pages/Kapcsolat/Kapcsolat';

class Content extends React.Component {
  static propTypes = {
    contentRef: PropTypes.node,
  };

  render() {
    return (
      <div className="content" ref={this.props.contentRef}>
        <Switch>
          <Route exact path="/" component={Fooldal} />
          <Route path="/ceginformacio" component={Ceginformacio} />
          <Route exact path="/futomu-beallitas" component={FutomuBeallitas} />
          <Route path="/futomu-beallitas/informaciok" component={FutomuInfok} />
          <Route exact path="/gumiszerviz" component={Gumiszerviz} />
          <Route path="/gumiszerviz/informaciok" component={GumiInfok} />
          <Route path="/fotogaleria" component={Fotogaleria} />
          <Route path="/kapcsolat" component={Kapcsolat} />
          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </div>
    );
  }
}

export default Content;

import React from 'react';

import { FOUNDED } from '../../../constants';

import './Footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        &copy; Copyright {FOUNDED}-{new Date().getFullYear()}. banfiszerviz.hu
      </div>
    );
  }
}

export default Footer;

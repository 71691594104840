import React from 'react';
import PropTypes from 'prop-types';

import './Main.scss';
import Content from '../../layouts/Content/Content';
import Menu from '../../fragments/Menu/Menu';
import Footer from '../../fragments/Footer/Footer';

class Main extends React.Component {
  static propTypes = {
    contentRef: PropTypes.node,
  };

  render() {
    return (
      <div className="main">
        <div className="main__menu">
          <Menu contentRef={this.props.contentRef} />
        </div>
        <div className="main__content">
          <Content contentRef={this.props.contentRef} />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Main;

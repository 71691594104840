import React from 'react';

class Gumiszerviz extends React.Component {
  render() {
    return (
      <div>
        <h1>Gumiszerviz</h1>
        <p>Gumiszervizünkben személy és teherautó gumik adás-vételével ,szerelésével ,javításával foglalkozunk.</p>
        <p>
          E témakörre szakosodott munkatársaink nagy rutinnal, és megfelelő ismeretekkel rendelkeznek ahhoz , hogy ön maximálisan elégedett legyen. A vezető abroncsgyártók teljes
          választékával állunk az önök rendelkezésére.
        </p>
        <p>
          Teljesen mindegy, hogy ön gazdaságosan, dinamikusan ill. sportosan szeret vezetni, mi megtaláljuk a stílusának leginkább megfelelő gumiabroncsokat. Kérje ez irányú
          tájékoztatásunkat.
        </p>
        <p>
          Szakembereink az abroncsokat a korszerű gépek segítségével szerelik fel a felnikre, centrírozzák és a kész kerekeket a megfelelő forgatónyomaték alkalmazásával
          csavarozzák fel a járművére.
        </p>
        <p>
          Az abroncsok szezonális (téli/nyári) cseréje alkalmával a kerekeket automatikusan, a járművön elfoglalt pozíciójuknak megfelelően cseréljük , ezáltal biztosítva a négy
          gumi egyenletes kopását. Ennek feltétele természetesen az, hogy a kerekeket a legutóbbi cserénél megfelelően bejelölték. Ellenőrizzük a profilmélységet, újra centrírozzuk
          a kerekeket, gondoskodunk az optimális nyomásról, elvégezzük gumi- ill. kerékcserét mielőtt az autójára felszerelnénk. - Önnek csak annyi a dolga, hogy időpontot
          egyeztessen a kerékcserére, a többit mi elintézzük.
        </p>
        <p>
          Az Ön kerekei valószínűleg nem állnak állandóan figyelme középpontjában, ez azonban komoly következményekkel járhat. Javasoljuk tehát, hogy saját biztonsága érdekében
          keresse fel a szakszervizünket egy <span className="red bold upcase">ingyenes</span> abroncsellenőrzésre. Végül is az abroncs jelenti az egyetlen kapcsolatot ön és az
          úttest között! Ellenőrizzük abroncsainak profilmélységét, kopását, nyomását, elhasználódását és az oldalfalak esetleges sérüléseit. Rendellenesség észlelése vagy defekt
          esetén forduljon hozzánk bizalommal, kollégáink rendelkezésére állnak és gondoskodnak az ön biztonságáról.
        </p>
        <p>
          Gondoljon a pénztárcájára, hiszen a túl késői gumicserék miatt bekövetkező esetleges balesetek nem csupán kellemetlenségekkel járnak, de komoly biztonsági és anyagi
          kihatásuk is lehet.
        </p>
      </div>
    );
  }
}

export default Gumiszerviz;

import React from 'react';
import './Kapcsolat.scss';

class Kapcsolat extends React.Component {
  render() {
    return (
      <div>
        <h1>Elérhetőségeink</h1>

        <h2>Cím:</h2>
        <p>
          <a
            href="https://www.google.com/maps/d/u/0/viewer?msa=0&ll=47.6683931824432%2C17.631318999999962&spn=0.008728%2C0.01929&iwloc=0004bfa50732eefeabcb2&mid=1lpMXDvW7vhPUdeWYGplHdl37B9E&z=16"
            target="_blank"
            rel="noopener noreferrer">
            Győr, Marcalváros II., Illyés Gyula utcai garázsváros
          </a>
          <br />
          (DAC pálya mellett)
        </p>

        <h2>GPS koordináták:</h2>
        <p>47.668118,17.631335</p>

        <h2>Nyitva tartás:</h2>
        <p>
          Hétfő - Péntek: 08:00 - 16:00
          <br />
          Szombat: 08:00 - 12:00
        </p>

        <h2>Telefonszámaink:</h2>
        <div className="line" />
        <table className="kapcsolat">
          <tbody>
            <tr>
              <th>Futómű&shy;beállítás:</th>
              <td>
                <a href="tel:+36309488898">06-30/948-8898</a>
              </td>
            </tr>
            <tr>
              <th>Gumi&shy;szerelés- javítás:</th>
              <td>
                <a href="tel:+36702654574">06-70/265-4574</a>
              </td>
            </tr>
            <tr>
              <th>Autóüveg sötétítés:</th>
              <td>
                <a href="tel:+36304605510">06-30/460-5510</a>
              </td>
            </tr>
            <tr>
              <th>Fényszóró polírozás és fóliázás:</th>
              <td>
                <a href="tel:+36304605510">06-30/460-5510</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Kapcsolat;

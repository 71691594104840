import React from 'react';
import PropTypes from 'prop-types';

import './Menu.scss';
import { NavLink } from 'react-router-dom';

class Menu extends React.Component {
  static propTypes = {
    contentRef: PropTypes.node,
  };

  clickMenu = () => {
    setTimeout(() => {
      if (window.innerWidth < 1280) {
        window.scrollTo(0, this.props.contentRef.current.offsetTop);
      }
    });
  };

  render() {
    return (
      <div className="menu">
        <NavLink to="/ceginformacio" activeClassName="menu__item_active" onClick={this.clickMenu}>
          Céginformáció
        </NavLink>
        <NavLink to="/futomu-beallitas" exact activeClassName="menu__item_active" onClick={this.clickMenu}>
          Futómű beállítás
        </NavLink>
        <NavLink to="/futomu-beallitas/informaciok" activeClassName="menu__item_active" onClick={this.clickMenu}>
          Futómű infók
        </NavLink>
        <NavLink to="/gumiszerviz" exact activeClassName="menu__item_active" onClick={this.clickMenu}>
          Gumiszerviz
        </NavLink>
        <NavLink to="/gumiszerviz/informaciok" activeClassName="menu__item_active" onClick={this.clickMenu}>
          Gumi infók
        </NavLink>
        <NavLink to="/fotogaleria" activeClassName="menu__item_active" onClick={this.clickMenu}>
          Fotógaléria
        </NavLink>
        <NavLink to="/kapcsolat" activeClassName="menu__item_active" onClick={this.clickMenu}>
          Elérhetőségeink
        </NavLink>
      </div>
    );
  }
}

export default Menu;

import React from 'react';

import { FOUNDED } from '../../../constants';
import { getAge } from '../../../functions';

import './Ceginformacio.scss';

class Ceginformacio extends React.Component {
  render() {
    return (
      <div className="ceginformacio">
        <h1>Céginformáció</h1>
        <p>
          Cégünk a <span className="upcase bold">Bánfi Futómű</span> {FOUNDED}-ben alakult.
        </p>
        <p>
          Futóműbeállító szervizünkben szinte az összes gépjármű típussal foglalkozunk és ezekhez mindig a legfrissebb futóműbeállítási adatokkal rendelkezünk! Ezalatt a
          személyautókat és a kisbuszokat értjük. Nyitási akcióként indult <span className="upcase bold red">ingyenes számítógépes futóműmérés</span> a nagy érdeklődésre való
          tekintettel még mindig tart.
        </p>
        <p>
          <span className="red bold">{getAge()} éves fennállásunk</span> bizonyítja, hogy futóműbeállítás és szervizelés terén is megálljuk a helyünket.
        </p>
        <p>
          Mindennemű szervizelési és javítási munkát magasan képzett és tapasztalt szakemberek végeznek, amit ön az elejétől a végéig figyelemmel kísérhet. A vállalkozásunknál lévő
          csapatszellemet és precizitást mi sem bizonyítja jobban mint az, hogy <span className="bold red">ugyanazokkal a kollégákkal dolgozunk nyitás óta</span>, és folyamatosan
          fejlesztjük magunkat!
        </p>
        <p>
          2004-ben bevezettük a <span className="bold red">törzsvásárlói kártya</span> szolgáltatásunkat, így ezzel is jelezzük hogy nagyra értékeljük az önök hűségét.
          <br />A törzsvásárlói kártya felhasználható az összes szolgáltatásra amiben nálunk részesülhetnek:
        </p>
        <ul>
          <li className="red bold upcase">Futómű-beállítás</li>
          <li>
            <span className="red bold upcase">Autógumi adás-vétel, szerviz</span> /személy, teher/
          </li>
          <li className="red bold upcase">Autóüveg sötétítés</li>
          <li className="red bold upcase">Fényszóró polírozás és fóliázás</li>
        </ul>
        <p className="center">
          Szlogenünk az Alacsony Árak Kiváló Minőségű Munka önmagáért beszél!
          <br />
          Tegyen próbára minket!
        </p>
      </div>
    );
  }
}

export default Ceginformacio;

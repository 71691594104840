import React from 'react';

class GumiInfok extends React.Component {
  render() {
    return (
      <div>
        <h1>Gumiabroncs információk</h1>
        <h2>Az egyetlen kapcsolat az úttest és Ön között!</h2>
        <p>
          A jármű abroncsainak gyakran kevesebb figyelmet szentelünk, mint az első pillantásra fontosabbnak tűnő motorteljesítménynek, biztonsági berendezéseknek és külsőségeknek.
        </p>
        <p>
          Alaposabb átgondolás után azonban rájövünk, hogy az ember és úttest közötti tényleges kapcsolatot az abroncsok biztosítják. - A jármű és úttest között működő erők az
          abroncsokat terhelik: a gyorsítás és fékezés során un. hosszirányú erők, kanyarvételnél pedig a gördülési iránnyal ellentétes oldalirányú erők hatnak.
        </p>
        <p>Minél nagyobb a jármű sebessége, annál nagyobb terhelés hárul a gumiabroncsokra...</p>
        <h2>Abroncsnyomás</h2>
        <ul>
          <li>
            <span className="bold">Az alacsony nyomás károsítja az abroncsokat.</span>
            <br />A túl puha abroncs a belső tér erőteljes felmelegedéséhez vezet, ezáltal károsodnak az oldalfalak. Az abroncs kopása felgyorsul és sérülékennyé válik a külső
            behatásokkal (pl. hegyes tárgyak stb.) szemben.
          </li>
          <li>
            <span className="bold">Az alacsony nyomás a gépjármű feletti kontroll elvesztését okozhatja.</span>
            <br />
            Ilyenkor a jármű kevésbé érzékeny a kormányzásra, vagyis az autó „felúszik”. Ezen felül a futófelület barázdái megkopnak és nem képesek a vizet megfelelően elvezetni.
            Így az autó ténylegesen „úszni” fog a vízfelületen „Aquaplaning”.
          </li>
          <li>
            <span className="bold">Az alacsony abroncsnyomás pénzbe kerül.</span>
            <br />
            Az abroncs gördülő-ellenállása megnő. Ez pedig az üzemanyag-fogyasztás jelentős növekedéséhez vezet.
          </li>
        </ul>
        <h2>Abroncsjelzések</h2>
        <p className="margin-bottom-0">
          Abroncsméret pl.: <span className="bold">185/65 R 14 86T</span>
        </p>
        <div className="line" />
        <table>
          <tbody>
            <tr>
              <th>185</th>
              <td>abroncsszélesség mm-ben</td>
            </tr>
            <tr>
              <th>65</th>
              <td>keresztmetszet visz.sz. (szélesség 65%-a)</td>
            </tr>
            <tr>
              <th>R</th>
              <td>Radial</td>
            </tr>
            <tr>
              <th>14</th>
              <td>felni átmérő colban</td>
            </tr>
            <tr>
              <th>86</th>
              <td>teherbírás mutató (LI - Load index)</td>
            </tr>
            <tr>
              <th>T</th>
              <td>sebesség mutató (SI - Speed index)</td>
            </tr>
            <tr>
              <th>M&S</th>
              <td>latyak és hó (téli gumi)</td>
            </tr>
          </tbody>
        </table>

        <p />

        <h2>Sebesség szimbólumok (Speed Index)</h2>
        <div className="line" />
        <table>
          <thead>
            <tr>
              <th>Speed Index</th>
              <th>max. sebesség (km/h)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>N</th>
              <td>140</td>
            </tr>
            <tr>
              <th>P</th>
              <td>150</td>
            </tr>
            <tr>
              <th>Q</th>
              <td>160</td>
            </tr>
            <tr>
              <th>R</th>
              <td>170</td>
            </tr>
            <tr>
              <th>S</th>
              <td>180</td>
            </tr>
            <tr>
              <th>T</th>
              <td>190</td>
            </tr>
            <tr>
              <th>U</th>
              <td>200</td>
            </tr>
            <tr>
              <th>H</th>
              <td>210</td>
            </tr>
            <tr>
              <th>V</th>
              <td>240</td>
            </tr>
            <tr>
              <th>W</th>
              <td>270</td>
            </tr>
            <tr>
              <th>Y</th>
              <td>300</td>
            </tr>
          </tbody>
        </table>

        <p />
        <h2>Teherbírás mutatók (Load Index)</h2>
        <div className="line" />
        <table>
          <thead>
            <tr>
              <th>LI</th>
              <th>kg</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>65</td>
              <td>290</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default GumiInfok;
